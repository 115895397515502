import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jricher/Projects/oauth.xyz-site/src/components/layout.js";
import { Link } from 'gatsby';
import ContinueRequest from '../components/continue';
import SEO from '../components/seo';
import Code from '../components/code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Continuation Request" keywords={['oauth', 'authorization', 'security']} mdxType="SEO" />
    <h1>{`Continuation Request`}</h1>
    <p>{`A continuation request is a form of request that continues the transaction for one of a number of reasons, based on the kind of interaction pattern that the client is capable of performing. `}</p>
    <ul>
      <li parentName="ul">{`The client has received a callback during `}<Link to="/interaction" mdxType="Link">{`Interaction`}</Link>{` with the user`}</li>
      <li parentName="ul">{`The client is waiting for the user to take some action and is polling the request`}</li>
      <li parentName="ul">{`The client has received information in response to a server challenge that will allow the request to progress`}</li>
    </ul>
    <ContinueRequest mdxType="ContinueRequest" />
    <h2>{`Interaction Reference`}</h2>
    <p>{`If the client instance has received an interaction reference through one of the interaction `}<inlineCode parentName="p">{`finish`}</inlineCode>{` mechanisms, the client will send that reference back to the AS.`}</p>
    <Code code={{
      "interact_ref": "4IFWWIKYBC2PQ6U56NL1"
    }} mdxType="Code" />
    <p>{`Note that the client instance has to validate the interaction hash before sending this reference back or else leave itself open to several forms of injection attack. Se the `}<Link to="/interaction" mdxType="Link">{`interaction`}</Link>{` page for more details.`}</p>
    <h2>{`Continuation Updates`}</h2>
    <p>{`If the client instance wants to request additional access token rights or additional subject information, the client instance can do so by sending the same `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` and `}<inlineCode parentName="p">{`subject`}</inlineCode>{` fields as it would inside the initial request. `}</p>
    <p>{`The client instance could also send a `}<inlineCode parentName="p">{`user`}</inlineCode>{` field if it has learned additional information about the current user that it wants to present to the AS.`}</p>
    <p>{`The continuation request is tied to a specific client instance, and so the `}<inlineCode parentName="p">{`client`}</inlineCode>{` field can't be sent in any continuation request.`}</p>
    <h2>{`Canceling an Ongoing Request`}</h2>
    <p>{`If the client instance wants to cancel an ongoing request, whether it's been granted or not, it can send an HTTP `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{` request to the continuation URL.`}</p>
    <h2>{`Response`}</h2>
    <p>{`The AS responds to a transaction continuation request with a standard `}<Link to="/response" mdxType="Link">{`response`}</Link>{`. Note that the AS can respond with a different `}<inlineCode parentName="p">{`continue`}</inlineCode>{` field than that used in the transaction continuation request, in which case the client instance needs to use the new values of the access token and uri within that section.`}</p>
    <h2>{`Authentication`}</h2>
    <p>{`A continuation request is always authenticated using the continuation access token issued in the `}<inlineCode parentName="p">{`continue`}</inlineCode>{` section of the initial `}<Link to="/response" mdxType="Link">{`response`}</Link>{`. Since this token is always bound to the client instance's key, this request is always signed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      