import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import 'bootstrap/dist/css/bootstrap.css'
import './layout.css'

const Layout = ({ children }) => (
      <>
        <Header/>
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div>
      </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
